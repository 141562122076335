"use client";

import { createContext } from "react";
import { UserStudent } from "@koble/graphql";

export interface UserStudentCardContextProps {
  userStudent: UserStudent;
  profileImagePlaceholder: string;
  userStudentLinkTo: string;
}

const CommonUserStudentCardContext = createContext<UserStudentCardContextProps>(
  {
    userStudent: {} as UserStudent,
    profileImagePlaceholder: "",
    userStudentLinkTo: "",
  }
);

export default CommonUserStudentCardContext;

import businessCompletionFields from "./businessCompletionFields";
import {
  ItemProps,
  percentCompleted,
  remainingPropsToComplete,
} from "./itemCompletionHelpers";
import jobCompletionFields from "./jobCompletionFields";
import userRecruiterCompletionFields from "./userRecruiterCompletionFields";
import userStudentCompletionFields from "./userStudentCompletionFields";
import userStudentVisibleFields from "./userStudentVisibleFields";

export {
  businessCompletionFields,
  jobCompletionFields,
  percentCompleted,
  remainingPropsToComplete,
  userRecruiterCompletionFields,
  userStudentCompletionFields,
  userStudentVisibleFields,
};

export type { ItemProps };

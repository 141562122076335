import { UserRecruiter } from "@koble/graphql";

import { ItemProps } from "./itemCompletionHelpers";

const userRecruiterCompletionFields: ItemProps<UserRecruiter>[] = [
  {
    key: "profileImageUrl",
    label: "Imagen de perfil",
  },
  {
    key: "gender",
    label: "Género",
  },
  {
    key: "about",
    label: "Acerca de",
  },
  {
    key: "workPosition",
    label: "Puesto",
  },
  {
    key: "dateOfBirth",
    label: "Fecha de nacimiento",
  },
];

export default userRecruiterCompletionFields;

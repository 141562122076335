import React from "react";

import useCommonUserStudentCard from "./useCommonUserStudentCard";

const CommonUserStudentCardBody = () => {
  const { userStudent } = useCommonUserStudentCard();

  const lineHeights = 1.4;
  const fontSize = 12;
  const numberOfLines = 2;
  const spanHeight = fontSize * numberOfLines * lineHeights;

  return (
    <div>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          fontSize,
          lineHeight: `${lineHeights}em`,
          height: `${spanHeight}px`, // Fixed height for 2 lines
          width: "100%", // Ensuring the span takes the full width
        }}
      >
        {userStudent.about}
      </span>
    </div>
  );
};

export default CommonUserStudentCardBody;

import React, { ReactNode } from "react";

import CommonUserStudentCardContext, {
  UserStudentCardContextProps,
} from "./CommonUserStudentCardContext";

const CommonUserStudentCardProvider = ({
  children,
  ...props
}: UserStudentCardContextProps & { children: ReactNode }) => {
  return (
    <CommonUserStudentCardContext.Provider value={{ ...props }}>
      {children}
    </CommonUserStudentCardContext.Provider>
  );
};

export default CommonUserStudentCardProvider;

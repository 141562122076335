import React from "react";
import { assets } from "@koble/assets/assets";
import { UserStudent } from "@koble/graphql";
import displayCommonModal from "@koble/ui/src/CommonModals/displayCommonModal";
import {
  remainingPropsToComplete,
  userStudentVisibleFields,
} from "@koble/utils/src/itemCompletion";
import { Alert, Avatar } from "antd";
import { HookAPI } from "antd/es/modal/useModal";

const displayUserStudentInvisibleProfileModal = ({
  modalApi,
  userStudent,
  title,
  subtitle,
}: {
  modalApi: HookAPI;
  userStudent: UserStudent;
  title: string;
  subtitle: string;
}) => {
  const toComplete = remainingPropsToComplete<UserStudent>(
    userStudent,
    userStudentVisibleFields
  );

  displayCommonModal({
    modalApi,
    type: "confirm",
    title,
    okText: "Completar perfil",
    okButtonProps: {
      href: "/onboarding",
    },
    icon: <Avatar src={assets.KOBOT_SAD_LARGE} alt="Kobot" />,
    alert: (
      <Alert
        message={
          <div>
            {subtitle}
            <br />
            <br />
            {toComplete.map((item) => (
              <li style={{ marginLeft: 12 }} key={item.key}>
                • {item.label}
              </li>
            ))}
          </div>
        }
        type="info"
        showIcon
      />
    ),
  });
};

export default displayUserStudentInvisibleProfileModal;

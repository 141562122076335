import { UserStudent } from "@koble/graphql";

import { ItemProps } from "./itemCompletionHelpers";

const userStudentCompletionFields: ItemProps<UserStudent>[] = [
  {
    key: "isProfileVisible",
    label: "Perfil visible",
  },
  {
    key: "profileImageUrl",
    label: "Foto de perfil",
  },
  {
    key: "educations",
    label: "Educación",
  },
  {
    key: "academicProjects",
    label: "AcademicProjects",
  },
];

export default userStudentCompletionFields;

import { Dayjs } from "dayjs";

const monthsInSpanish = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const dayjsToMonthDatepickerFormat = (
  date: Dayjs,
  abbreviate = false,
  firstLetterIsUppercase = false
) => {
  const month = date.month();
  let monthName = monthsInSpanish[month];
  if (firstLetterIsUppercase)
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
  return `${abbreviate ? monthName.slice(0, 3) : monthName} ${date.year()}`;
};

export default dayjsToMonthDatepickerFormat;

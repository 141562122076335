import { Business } from "@koble/graphql";

import { ItemProps } from "./itemCompletionHelpers";

const businessCompletionFields: ItemProps<Business>[] = [
  {
    key: "backgroundImageUrl",
    label: "Imagen de portada",
  },
  {
    key: "profileImageUrl",
    label: "Imagen de perfil",
  },
  {
    key: "about",
    label: "Acerca de",
  },
  {
    key: "typicalDayDescription",
    label: "Un día común en la empresa",
  },
  {
    key: "whyJoinDescription",
    label: "Por qué unirse",
  },
  {
    key: "industry",
    label: "Tipo de industria",
  },
  {
    key: "websiteUrl",
    label: "Sitio web",
  },
  {
    key: "foundationDate",
    label: "Fecha de fundación",
  },
  {
    key: "businessSize",
    label: "Tamaño de la empresa",
  },
];

export default businessCompletionFields;

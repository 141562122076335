"use client";

import { createContext, Dispatch, SetStateAction } from "react";
import {
  AcademicProject,
  Career,
  Certification,
  Education,
  ExtraCurricularActivity,
  University,
  UserStudent,
  WorkExperience,
} from "@koble/graphql";

export interface DetailedInformation extends Partial<UserStudent> {
  universityData: {
    university?: University;
    newUniversityRequestName?: string;
  };
  careerData: {
    career?: Career;
    newCareerRequestName?: string;
  };
}

interface UserStudentProfileContextProps {
  userStudent?: UserStudent;
  setUserStudent: Dispatch<SetStateAction<UserStudent | undefined>>;
  uploadProfileImage: (file: File) => Promise<void>;
  updateGeneralInformation: (values: Partial<UserStudent>) => Promise<void>;
  updateDetailedInformation: (values: DetailedInformation) => Promise<void>;
  updateToolsAndSkills: (values: Partial<UserStudent>) => Promise<void>;
  upsertEducation: (values: Partial<Education>) => Promise<void>;
  upsertWorkExperience: (values: Partial<WorkExperience>) => Promise<void>;
  upsertExtraCurricularActivity: (
    values: Partial<ExtraCurricularActivity>
  ) => Promise<void>;
  upsertCertification: (values: Partial<Certification>) => Promise<void>;
  upsertAcademicProject: (values: Partial<AcademicProject>) => Promise<void>;
}

const UserStudentProfileContext = createContext<UserStudentProfileContextProps>(
  {
    userStudent: {} as UserStudent,
    setUserStudent: () => {},
    uploadProfileImage: async () => {},
    updateGeneralInformation: async () => {},
    updateDetailedInformation: async () => {},
    updateToolsAndSkills: async () => {},
    upsertEducation: async () => {},
    upsertWorkExperience: async () => {},
    upsertExtraCurricularActivity: async () => {},
    upsertCertification: async () => {},
    upsertAcademicProject: async () => {},
  }
);

export default UserStudentProfileContext;

import React from "react";
import stringToColor from "@koble/utils/src/stringToColor";
import { Badge, Button, Space, Tag, Tooltip } from "antd";

import { CommonSymbology } from "../../commonSymbology";

import useCommonUserStudentCard from "./useCommonUserStudentCard";

const CommonUserStudentCardTags = () => {
  const { userStudent } = useCommonUserStudentCard();

  // get text of how many likes the recruiter gave to the student on spanish:
  const getLikeText = (likeCount: number) => {
    if (likeCount === 1) {
      return "Has dado 1 like";
    }
    return `Has dado ${likeCount} likes`;
  };

  const getSuperLikeText = (superLikeCount: number) => {
    if (superLikeCount === 1) {
      return "Has dado 1 super like";
    }
    return `Has dado ${superLikeCount} super likes`;
  };

  const getMatchText = (matchCount: number) => {
    if (matchCount === 1) {
      return "Tienes 1 match";
    }
    return `Tienes ${matchCount} matches`;
  };

  return (
    <div
      style={{
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {userStudent.area ? (
          <Tag color={stringToColor(userStudent.area.areaId)}>
            {userStudent.area?.spanishName}
          </Tag>
        ) : (
          <Tag style={{ backgroundColor: "white" }}>Sin área definida</Tag>
        )}
      </div>
      <div>
        <Space size="small">
          {userStudent.likeCount !== 0 && userStudent.likeCount && (
            <Badge
              count={userStudent.likeCount}
              size="small"
              offset={[0, 4]}
              color="#EB7A15"
            >
              <Tooltip
                title={getLikeText(userStudent.likeCount)}
                placement="top"
              >
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#EB7A15" }}
                  icon={<CommonSymbology.Like />}
                />
              </Tooltip>
            </Badge>
          )}
          {userStudent.superLikeCount !== 0 && userStudent.superLikeCount && (
            <Badge
              count={userStudent.superLikeCount}
              size="small"
              offset={[0, 4]}
              color="#1890FF"
            >
              <Tooltip
                title={getSuperLikeText(userStudent.superLikeCount)}
                placement="top"
              >
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#1890FF" }}
                  icon={<CommonSymbology.SuperLike />}
                />
              </Tooltip>
            </Badge>
          )}
          {userStudent.matchCount !== 0 && userStudent.matchCount && (
            <Badge
              count={userStudent.matchCount}
              size="small"
              offset={[0, 4]}
              color="#B85EE6"
            >
              <Tooltip
                title={getMatchText(userStudent.matchCount ?? 0)}
                placement="top"
              >
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#B85EE6" }}
                  icon={<CommonSymbology.Match />}
                />
              </Tooltip>
            </Badge>
          )}
        </Space>
      </div>
    </div>
  );
};

export default CommonUserStudentCardTags;

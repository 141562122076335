import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { CalendarOutlined } from "@ant-design/icons";
import { dayjsToMonthDatepickerFormat } from "@koble/utils/src/antDatepickerDateFormats";
import { Tooltip, Typography } from "antd";
import dayjs from "dayjs";

import useCommonUserStudentCard from "./useCommonUserStudentCard";

const { Text } = Typography;

const CommonUserStudentCardFooter = () => {
  const { userStudent } = useCommonUserStudentCard();

  const getStudentLocation = () => {
    if (userStudent.place) {
      return (
        userStudent.place.city?.name ??
        userStudent.place.state?.name ??
        userStudent.place.country?.name
      );
    }

    return "Sin ubicación";
  };

  const getStudentAge = () => {
    const today = new Date();
    const birthDate = new Date(userStudent.dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // If the current month is less than the birth month or
    // if it's the same month but the current day is less than the birth day,
    // subtract one year from the age.
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }

    return `${age} años`;
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <div>
          <MdOutlineLocationOn
            style={{
              marginRight: 4,
              fontSize: 12,
              top: 1,
              position: "relative",
            }}
          />
          <Text style={{ marginBottom: 0, fontSize: 12 }}>
            {getStudentLocation()}
          </Text>
        </div>
        <div>
          <Tooltip
            title={`Nació el ${new Date(
              userStudent.dateOfBirth
            ).getDate()} de ${dayjsToMonthDatepickerFormat(
              dayjs(userStudent.dateOfBirth)
            )}`}
          >
            <CalendarOutlined
              style={{
                marginRight: 4,
                fontSize: 12,
              }}
            />
            <Text
              style={{
                marginBottom: 0,
                fontSize: 12,
              }}
            >
              {getStudentAge()}
            </Text>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CommonUserStudentCardFooter;

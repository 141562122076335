import React, { ReactNode } from "react";
import { UserStudent } from "@koble/graphql";
import { Card, Divider } from "antd";

import CommonUserStudentCardBody from "./CommonUserStudentCardBody";
import CommonUserStudentCardFooter from "./CommonUserStudentCardFooter";
import CommonUserStudentCardHeader from "./CommonUserStudentCardHeader";
import CommonUserStudentCardProvider from "./CommonUserStudentCardProvider";
import CommonUserStudentCardTags from "./CommonUserStudentCardTags";

const CommonUserStudentCard = ({
  userStudent,
  profileImagePlaceholder,
  extraContent,
  userStudentLinkTo,
}: {
  userStudent: UserStudent;
  profileImagePlaceholder: string;
  extraContent?: ReactNode;
  userStudentLinkTo: string;
}) => {
  return (
    <CommonUserStudentCardProvider
      userStudent={userStudent}
      profileImagePlaceholder={profileImagePlaceholder}
      userStudentLinkTo={userStudentLinkTo}
    >
      <Card styles={{ body: { padding: 15 } }}>
        <CommonUserStudentCardTags />
        <CommonUserStudentCardHeader />
        <CommonUserStudentCardBody />
        <Divider style={{ marginBottom: 7, marginTop: 10 }} />
        <CommonUserStudentCardFooter />
        {extraContent && <div style={{ marginTop: 8 }}>{extraContent}</div>}
      </Card>
    </CommonUserStudentCardProvider>
  );
};

export default CommonUserStudentCard;

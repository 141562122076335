export interface ItemProps<T> {
  key: keyof T; // Ensure 'key' is one of the keys of T
  alternativeKey?: keyof T; // Ensure 'alternativeKey' is one of the keys of T
  label: string;
}

export const remainingPropsToComplete = <T>(
  item: T,
  itemProps: ItemProps<T>[]
): ItemProps<T>[] => {
  return itemProps.reduce<ItemProps<T>[]>((acc, prop) => {
    const propertyByKey = item[prop.key as keyof T]; // Cast to keyof T to satisfy TypeScript
    const propertyByAlternativeKey = item[prop.alternativeKey as keyof T]; // Cast to keyof T to satisfy TypeScript

    const doesPropertyExistByKey =
      propertyByKey !== undefined &&
      propertyByKey !== null &&
      !(Array.isArray(propertyByKey) && propertyByKey.length === 0);

    const doesPropertyExistByAlternativeKey =
      propertyByAlternativeKey !== undefined &&
      propertyByAlternativeKey !== null &&
      !(
        Array.isArray(propertyByAlternativeKey) &&
        propertyByAlternativeKey.length === 0
      );

    if (!doesPropertyExistByKey && !doesPropertyExistByAlternativeKey) {
      acc.push(prop);
    }

    return acc;
  }, []);
};

export const percentCompleted = <T>(
  item: T,
  itemProps: ItemProps<T>[]
): number => {
  const remaining = remainingPropsToComplete(item, itemProps);
  const total = itemProps.length;
  const completed = total - remaining.length;
  return (completed / total) * 100;
};

import React, { ReactNode } from "react";
import { ModalFuncProps, Space, Typography } from "antd";
import { HookAPI } from "antd/es/modal/useModal";

interface DisplayCommonModalProps extends ModalFuncProps {
  modalApi: HookAPI;
  alert?: ReactNode;
  type?: "info" | "success" | "error" | "warning" | "confirm";
}

const displayCommonModal = ({
  modalApi,
  content,
  icon,
  title,
  alert,
  type,
  ...rest
}: DisplayCommonModalProps) => {
  // clone the icon to set the fontSize to 18 while keeping the original color
  const newIcon =
    icon &&
    React.cloneElement(icon as React.ReactElement, {
      style: { fontSize: 18, ...(icon as React.ReactElement).props.style },
    });

  return modalApi[type ?? "info"]({
    content: (
      <div style={{ marginBottom: 6 }}>
        <Space
          size={8}
          style={{
            marginBottom: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          {newIcon && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {newIcon}
            </div>
          )}
          <Typography.Title level={5} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Space>
        <div style={{ width: "100%", marginTop: 16 }}>{content}</div>
        {alert && <div style={{ marginTop: 12 }}>{alert}</div>}
      </div>
    ),
    icon: null,
    centered: true,
    cancelText: "Cancelar",
    okText: "Aceptar",
    ...rest,
  });
};

export default displayCommonModal;

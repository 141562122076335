import { UserStudent } from "@koble/graphql";

import { ItemProps } from "./itemCompletionHelpers";

const userStudentVisibleFields: ItemProps<UserStudent>[] = [
  {
    key: "area",
    label: "Área",
  },
  {
    key: "dateOfBirth",
    label: "Fecha de nacimiento",
  },
  {
    key: "place",
    label: "Ubicación",
  },
  {
    key: "workScheduleType",
    label: "Disponibilidad",
  },
  {
    key: "expectations",
    label: "Expectativas",
  },
  {
    key: "keyCompetencies",
    label: "Destrezas",
  },
  {
    key: "skills",
    label: "Habilidades técnicas",
  },
  {
    key: "university",
    alternativeKey: "newUniversityRequest",
    label: "Universidad",
  },
  {
    key: "career",
    alternativeKey: "newCareerRequest",
    label: "Carrera",
  },
  {
    key: "about",
    label: "Acerca de mí",
  },
];

export default userStudentVisibleFields;

"use client";

import React from "react";
import { MessageOutlined } from "@ant-design/icons";
import { Job, UserStudent } from "@koble/graphql";
import { confetti } from "@tsparticles/confetti";
import { Alert, Col, Row } from "antd";
import { HookAPI } from "antd/es/modal/useModal";

import CommonJobCard from "../CommonJob/CommonJobCard/CommonJobCard";
import displayCommonModal from "../CommonModals/displayCommonModal";
import { CommonSymbology } from "../commonSymbology";
import CommonUserStudentCard from "../CommonUserStudent/CommonUserStudentCard";

const displayMatchModal = ({
  modalApi,
  job,
  userStudent,
  chatId,
  jobProfileImagePlaceholder,
  userStudentProfileImagePlaceholder,
}: {
  modalApi: HookAPI;
  job: Job;
  userStudent: UserStudent;
  chatId: string;
  jobProfileImagePlaceholder: string;
  userStudentProfileImagePlaceholder: string;
}) => {
  (async () => {
    await confetti("tsparticles", {
      particleCount: 200,
      angle: 60,
      spread: 300,
      gravity: 0,
      origin: { x: 0 },
      zIndex: 999999999,
    });
    await confetti("tsparticles", {
      particleCount: 200,
      angle: 120,
      spread: 300,
      gravity: 0,
      origin: { x: 1 },
      zIndex: 999999999,
    });
  })();

  displayCommonModal({
    modalApi,
    width: 700,
    icon: <CommonSymbology.Match style={{ fontSize: 18 }} />,
    title: "Nuevo Match",
    alert: (
      <Alert
        message="Ahora puedes comunicarte con tu match a través del chat. Asegúrate de enviar un mensaje lo antes posible para incrementar tus probabilidades de éxito."
        type="info"
        showIcon
      />
    ),
    okText: "Enviar mensaje",
    okButtonProps: {
      icon: <MessageOutlined />,
      href: `/chat/${chatId}`,
    },
    content: (
      <div>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <CommonUserStudentCard
              userStudent={userStudent}
              profileImagePlaceholder={userStudentProfileImagePlaceholder}
              userStudentLinkTo=""
            />
          </Col>
          <Col xs={24} md={12}>
            <CommonJobCard
              job={job}
              profileImagePlaceholder={jobProfileImagePlaceholder}
              jobLinkTo=""
            />
          </Col>
        </Row>
      </div>
    ),
  });
};

export default displayMatchModal;

import { Job } from "@koble/graphql";

import { ItemProps } from "./itemCompletionHelpers";

const jobCompletionFields: ItemProps<Job>[] = [
  {
    key: "about",
    label: "Acerca de",
  },
  {
    key: "title",
    label: "Título",
  },
  {
    key: "backgroundImageUrl",
    label: "Imagen de portada",
  },
  {
    key: "currency",
    label: "Moneda",
  },
  {
    key: "salaryRange",
    label: "Rango salarial",
  },
  {
    key: "paymentFrequency",
    label: "Frecuencia de pago",
  },
  {
    key: "benefits",
    label: "Beneficios",
  },
  {
    key: "area",
    label: "Área",
  },
  {
    key: "keyCompetencies",
    label: "Competencias clave",
  },
  {
    key: "languageLanguageLevels",
    label: "Idiomas",
  },
  {
    key: "skills",
    label: "Habilidades",
  },
  {
    key: "workModes",
    label: "Modos de trabajo",
  },
];

export default jobCompletionFields;
